"use client";

import Link from "next/link";
import cn from "classnames";
import { usePathname } from "next/navigation";

import styles from "./Logo.module.scss";

export const Logo = () => {
  const theme =
    (process.env.NEXT_PUBLIC_UI_THEME as "core" | "ekonomi") || "core";

  const pathName = usePathname();

  return (
    <Link
      href="/"
      className={cn([
        styles.headerLogo,
        theme === "core" ? styles.coreLogo : styles.economyLogo,
      ])}
      onClick={(e) => {
        if (pathName === "/") {
          e.preventDefault();
          window.location.reload(); //refresh page when logo is clicked from startpage
        }
      }}
      prefetch={false}
    >
      <span className={styles.hiddenText}>Hem</span>
    </Link>
  );
};
