import type { CategoriesResponse } from "../../../../../types/content";
import type { MenuItem } from "../../MenuItem/MenuItem";

import { MenuGroup } from "../MenuGroup";

interface CategoryMenuGroup {
  text: string;
  icon?: string;
  href: string;
}

const NewsCategoriesGroup = ({
  categoryData,
}: {
  categoryData: CategoriesResponse;
}) => {
  if (!categoryData) return null;

  const categoryItems = categoryData.reduce(
    (acc: CategoryMenuGroup[], category) => {
      if (category.type !== "CategoryDivider") {
        return [
          ...acc,
          {
            text: category.title,
            icon: category.icon?.image_url,
            href: `/${category.slug}`,
            type: "link",
          },
        ];
      }
      return acc;
    },
    [],
  ) as MenuItem[];

  return <MenuGroup title="Nyheter & Fördjupning" items={categoryItems} />;
};

export { NewsCategoriesGroup };
