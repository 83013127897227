"use client";

import { useEffect } from "react";

import type { CategoriesResponse } from "../../../types/content";
import type { SpidSettings } from "../../../types/settings";

import styles from "./Menu.module.scss";
import { MenuFooter } from "./MenuFooter/MenuFooter";
import { MenuSearch } from "./MenuSearch/MenuSearch";
import { UserMenuGroup } from "./MenuGroup/UserMenuGroup/UserMenuGroup";
import { AboutOmniGroup } from "./MenuGroup/AboutOmniGroup/AboutOmniGroup";
import { NewsCategoriesGroup } from "./MenuGroup/NewsCategoriesGroup/NewsCategoriesGroup";

const Menu = ({
  categoryData,
  isLoggedIn,
  isPremium,
  userSettings,
}: {
  categoryData?: CategoriesResponse;
  isLoggedIn: boolean;
  isPremium: boolean;
  userSettings?: SpidSettings;
}) => {
  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div
      className={styles.overlay}
      role="menu"
      id="menu"
      aria-labelledby="menubutton"
    >
      <div className={styles.container}>
        <UserMenuGroup
          isLoggedIn={isLoggedIn}
          isPremium={isPremium}
          userSettings={userSettings}
        />
        {categoryData && <NewsCategoriesGroup categoryData={categoryData} />}
        <MenuSearch />
        <AboutOmniGroup />
        <MenuFooter />
      </div>
    </div>
  );
};

export { Menu };
