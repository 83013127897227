"use client";

import { useRouter } from "next/navigation";
import { useState } from "react";

import type { ChangeEvent, FormEvent } from "react";

import styles from "./MenuSearch.module.scss";
import { Button } from "../../../Button/Button";

const MenuSearch = () => {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params = new URLSearchParams();
    params.append("q", searchTerm);
    params.append("tab", "articles");
    router.push(`/sok?${params.toString()}`);
  };

  return (
    <form className={styles.groupContainer} onSubmit={handleSearch}>
      <label className={styles.groupTitle} htmlFor="search-input">
        Sök efter artiklar och ämnen
      </label>
      <div className={styles.inputFieldContainer}>
        <input
          className={styles.inputField}
          id="search-input"
          type="text"
          placeholder="Ange t.ex ord i rubriken"
          value={searchTerm}
          onChange={handleInputChange}
          aria-label="Search field"
        />
        <div className={styles.submitFieldContainer}>
          <Button
            text="Sök"
            size="large"
            variant="primary"
            slug="search"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export { MenuSearch };
